<template>
  <div>
    <div class="header-title">{{ $route.meta.title }}</div>
    <div class="">
      <div>
        <div class="btn-con" style="float: left">
          <button
            type="button"
            class="next-btn next-medium next-btn-primary"
            style="margin-right: 8px"
            @click="adduser"
          >
            <span class="next-btn-helper">创建权限策略</span>
          </button>
        </div>
        <el-input
          v-model="page.search"
          prefix-icon="el-icon-search"
          size="small"
          style="width: 300px"
          placeholder="输入权限名"
          clearable
          @keyup.enter.native="get_list('reset')"
          @clear="get_list('reset')"
        />
      </div>

      <div class="list-box">
        <Loading :show="loading">
          <el-table
            border
            :data="tableData"
            style="width: 100%"
            :header-cell-style="headerCallStyle"
            @selection-change="handleCheckedCitiesChange"
          >
            <el-table-column type="selection" align="center" width="55" />
            <el-table-column prop="name" label="权限名称">
              <template slot-scope="{ row }">
                <button
                  type="button"
                  class="next-btn next-medium next-btn-primary next-btn-text"
                  style="margin-right: 8px"
                  @click="handle(row)"
                >
                  {{ row.name }}
                </button>
              </template>
            </el-table-column>
            <el-table-column prop="domain_name" label="所属域" />
            <el-table-column prop="client_name" label="所属应用" />
            <el-table-column prop="copy_from_permission_domain" label="跨域拷贝源域名" />
            <el-table-column prop="remark" label="备注" />
            <el-table-column prop="create_time" label="创建时间" />
            <el-table-column prop="address" label="操作">
              <template slot-scope="{ row }">
                <button
                  type="button"
                  class="next-btn next-medium next-btn-primary next-btn-text"
                  style="margin-right: 8px"
                  @click="delete_role(row)"
                >
                  <span class="next-btn-helper">删除</span>
                </button>
              </template>
            </el-table-column>
          </el-table>
        </Loading>
        <div style="padding: 20px 10px 10px; font-size: 18px">
          <el-row>
            <el-col :span="12">
              <el-checkbox
                v-model="checkAll"
                :indeterminate="isIndeterminate"
                style="margin-left: 10px"
              >
                <button
                  :disabled="!disabledAll"
                  type="button"
                  class="next-btn next-medium next-btn-primary next-btn-text"
                  style="margin-right: 8px"
                  @click="dialogVisible = true"
                >
                  跨域拷贝
                </button>
              </el-checkbox>
            </el-col>
            <el-col :span="12">
              <div style="text-align: right">
                <el-pagination
                  :page-size="page.pageSize"
                  :current-page="page.pageNum"
                  background
                  layout="total, prev, pager, next"
                  :total="total"
                  @current-change="handleCurrentChange"
                />
              </div>
            </el-col>
          </el-row>
        </div>
      </div>

      <el-drawer
        ref="drawer"
        custom-class="drawer-warp"
        title="新增授权"
        :visible.sync="drawer"
      >
        <div class="drawer__content">
          <el-form
            ref="ruleForm"
            :model="form"
            :rules="rules"
            label-width="100px"
            class="demo-ruleForm"
          >
            <el-form-item label="域">
              <span class="form_span">ainnovation</span>
            </el-form-item>
            <el-form-item label="用户上限" prop="name">
              <el-input v-model="form.name" />
            </el-form-item>
            <el-form-item label="用户组上限" prop="name">
              <el-input v-model="form.name" />
            </el-form-item>
            <el-form-item label="角色上限" prop="name">
              <el-input v-model="form.name" />
            </el-form-item>
            <el-form-item label="权限上限" prop="name">
              <el-input v-model="form.name" />
            </el-form-item>
          </el-form>
        </div>
        <div class="panel-footer-drawer custom-panel-footer">
          <div class="btn-con" style="float: left">
            <button
              type="button"
              class="next-btn next-medium next-btn-primary"
              style="margin-right: 8px"
            >
              <span class="next-btn-helper">确定</span>
            </button>
            <button
              type="button"
              class="next-btn next-medium next-btn-normal"
              style="margin-right: 8px"
              @click="$refs.drawer.closeDrawer()"
            >
              <span class="next-btn-helper">取消</span>
            </button>
          </div>
        </div>
      </el-drawer>
    </div>
    <el-dialog title="请选择域" :visible.sync="dialogVisible" width="350px">
      <el-select v-model="domain_id_list" multiple placeholder="请选择">
        <el-option
          v-for="item in domain_list"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        />
      </el-select>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="assingFun">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  permissions_list,
  rem_permission,
  domain_assing
} from '@/api/policies'
import { refresh_token } from '@/api/login'
import { get_refresh_token, getToken } from '@/utils/auth'
import { all_main_user_list } from '@/api'

export default {
  data () {
    return {
      domain_id_list: [],
      dialogVisible: false,
      domain_list: [],
      tableData: [],
      total: 0,
      drawer: false,
      form: {},
      rules: {},
      ids: [],
      checkAll: false,
      isIndeterminate: true,
      disabledAll: false,
      page: { search: '', pageNum: 1, pageSize: 10 }
    }
  },
  computed: {
    headerCallStyle () {
      return {
        background: '#eff3f8',
        color: '#333',
        fontWeight: 400,
        fontSize: '12px'
      }
    }
  },
  created () {
    this.get_list()
    this.get_domain_All()
  },
  methods: {
    async get_domain_All () {
      const { data } = await all_main_user_list()

      this.domain_list = data
    },
    assingFun () {
      const postData = {
        permission_id_list: this.ids,
        domain_id_list: this.domain_id_list
      }
      if (this.domain_id_list.length > 0) {
        domain_assing(postData)
          .then((res) => {
            if (res.code == 0) {
              this.$message({
                showClose: true,
                message: res.msg,
                type: 'success'
              })
            }
            this.dialogVisible = false
            this.domain_id_list = []
            this.get_list()
          })
          .catch(() => {
            this.dialogVisible = false
            this.domain_id = []
            this.get_list()
          })
      } else {
        alert('请先选择域！')
      }
    },

    handleCheckedCitiesChange (list) {
      const checkedCount = list.length
      this.disabledAll = checkedCount
      this.checkAll = checkedCount === this.tableData.length
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.tableData.length
      this.ids = list.map(({ id }) => id)
    },
    refresh_token_new () {
      const data = {
        grant_type: 'client',
        access_token: getToken(),
        refresh_token: get_refresh_token()
      }
      refresh_token(data).then((res) => {})
    },
    async get_list (reset) {
      if (reset == 'reset') {
        this.page.pageNum = 1
      }

      this.loading = true
      const params = this.page
      const data = await permissions_list(params)
      this.loading = false
      this.tableData = data.data

      this.total = data.count
    },
    adduser () {
      this.$router.push('/policies/add')
    },
    handle (row) {
      this.$router.push({
        path: '/policies/handle',
        query: {
          id: row.id,
          domain_id: row.domain_id
        }
      })
    },
    handleCurrentChange (val) {
      this.page.pageNum = val
      this.get_list()
    },
    delete_role (row) {
      const params = {
        permission_id: row.id,
        domain_id: row.domain_id
      }
      this.$confirm('是否确认删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          rem_permission(params).then((res) => {
            this.$message({
              showClose: true,
              message: res.msg,
              type: 'success'
            })
            this.page.pageNum = 1
            // const totalPage = Math.ceil((this.total - 1) / this.page.pageSize)
            // const currengpage = this.page.pageNum > totalPage ? totalPage : this.page.pageNum
            // this.page.pageNum = currengpage < 1 ? 1 : currengpage
            this.get_list()
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    }
  }
}
</script>

<style>
</style>
