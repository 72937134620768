import request from '@/utils/request'

// 列表
export function permissions_list (params) {
  return request({
    url: '/v1/permission/permissions',
    method: 'get',
    params
  })
}
// 删除
export function rem_role (id) {
  return request({
    url: '/v1/permission/role',
    method: 'delete',
    data: { role_id: id }
  })
}

// 新增
export function add_permission (data) {
  return request({
    url: '/v1/permission/permission',
    method: 'post',
    data
  })
}

// ldap连接测试
export function test_connectivity (row) {
  return request({
    url: '/v1/setting/test_connectivity',
    method: 'get',
    params: row
  })
}

// 查看返回数据
export function get_info (id) {
  return request({
    url: '/v1/permission/permission',
    method: 'get',
    params: { permission_id: id }
  })
}
// 查看返回数据
export function get_permission_client_info (params) {
  return request({
    url: '/v1/permission/permission_client_info',
    method: 'get',
    params
  })
}

// 修改ldap数据
export function edit_permission (data) {
  return request({
    url: '/v1/permission/permission',
    method: 'put',
    data
  })
}

// 删除
export function rem_permission (data) {
  return request({
    url: '/v1/permission/permission',
    method: 'delete',
    data
  })
}

// 跨域拷贝

export function domain_assing (data) {
  return request({
    url: '/v1/permission/copy_permissions',
    method: 'post',
    data
  })
}
// 授权 secret
export function get_permission_secret_list (data) {
  return request({
    url: '/v1/permission/direct/permission_secret',
    method: 'get',
    params: data
  })
}
